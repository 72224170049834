import { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { generateJWT } from "./helpers";

export default function ChatWidget({ name, onLoad, toggleOffer }) {
  const chatEl = useRef(null);
  const widget = useRef(null);
  const savedUser = useRef(window.localStorage.getItem("chat_id"));
  // const savedName = useRef(window.localStorage.getItem("chat_name"));

  useEffect(() => {
    if (!window.now4real) {
      window.now4real = window.now4real || {};
      window.now4real.config = {
        target: "api+widget",
        widget: {
          container: "chatbox",
          color_external_background: "#2f80ed",
          color_external_text: "#FFF",
          color_internal_background: "#2f80ed",
          color_internal_text: "#FFF",
          logo_url:
            "https://restartix.ro/wp-content/uploads/2023/01/logo-white.png", // premium feature
          align: "left",
          map_enabled: false,
          ranking_enabled: false,
          language: "ro",
        },
        scope: "page",
        custom_auth: {
          enabled: true,
          jwt: savedUser.current,
        },
      };
      window.now4real.onload = function () {
        if (!window.now4real.LOGIN_STATUS.user) {
          let finalname = name;

          if (!name) {
            finalname = prompt(
              "Completează cu numele tău pentru a putea scrie mesaje pe chat:"
            );
          }

          const id = (Math.random() + 1).toString(36).substring(7);
          const jwt = generateJWT(id, finalname);

          window.now4real.config.custom_auth.jwt = jwt;
          window.localStorage.setItem("chat_id", jwt);
          window.localStorage.setItem("chat_name", finalname);
          window.now4real.reload();
        }

        widget.current = window.now4real;
        onLoad(widget.current);

        window.now4real.subscribe("LIST_CHAT_MESSAGES", (response) => {
          const message = response.data.message;
          const author = response.data.author.name;

          if (
            message &&
            message.includes("#oferta") &&
            author === "Echipa RestartiX"
          ) {
            toggleOffer(true);
          }
        });
      };
      window.now4real.onerror = function (error) {
        console.log("Now4real has failed to load", error.code, error.message);
      };

      (function () {
        var n4r = document.createElement("script");
        n4r.type = "text/javascript";
        n4r.async = true;
        n4r.src = "https://cdn.now4real.com/now4real.js";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(n4r, s);
      })();
    }
  }, [name, onLoad, toggleOffer]);

  return (
    <Box ref={chatEl} id="chatbox" sx={{ height: "100%", display: "flex" }} />
  );
}
