import { Helmet } from "react-helmet";

export default function SocialTags() {
  return (
    <Helmet>
      <meta
        name="description"
        content="Interviuri realizate de Alexandru Ilie în cadrul campaniei Descoperă Valoarea Experienței"
      />
      <meta property="og:url" content="https://seniori.restartix.ro" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Atelierele Excelenței" />
      <meta
        property="og:description"
        content="Interviuri realizate de Alexandru Ilie în cadrul campaniei Descoperă Valoarea Experienței"
      />
      <meta
        property="og:image"
        content="https://seniori.restartix.ro/hero-bg.jpg"
      />
      <meta property="og:site_name" content="Atelierele Excelenței" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="https://seniori.restartix.ro" />
      <meta name="twitter:title" content="Atelierele Excelenței" />
      <meta
        name="twitter:description"
        content="Interviuri realizate de Alexandru Ilie în cadrul campaniei Descoperă Valoarea Experienței"
      />
      <meta
        name="twitter:image"
        content="https://seniori.restartix.ro/hero-bg.jpg"
      />
    </Helmet>
  );
}
